import { Checkbox, TextField, Paper, styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Container, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


import { interventionFormSchema, type InterventionFormData } from "./schemas";
import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";

export function MatrixForm() {
    const [snackbar, setSnackbar] = useState<{
        open: boolean;
        message: string;
        severity: "success" | "error";
    }>({
        open: false,
        message: "",
        severity: "success"
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm<InterventionFormData>({
        resolver: zodResolver(interventionFormSchema),
        defaultValues: {
            date: new Date(),
            timeIn: dayjs().format("HH:mm"),
            timeOut: "",
            seenAt: "",
            staffInitials: "",
            referredTo: "",
            supervised: "",
        }
    });

    const onSubmit = async (data: InterventionFormData) => {
        setIsSubmitting(true);
        try {
            const response = await fetch("/api/interventions", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            });

            if (!response.ok) throw new Error("Failed to save intervention");

            setSnackbar({
                open: true,
                message: "Intervention record saved successfully",
                severity: "success"
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Failed to save intervention record",
                severity: "error"
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const date = watch("date");

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Container maxWidth="xl" sx={{ py: 4 }}>
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    DATA CAPTURE FORM
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MatrixFormView
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        errors={errors}
                        date={date}
                        disabled={isSubmitting}
                    />
                    <div style={{ marginTop: "24px", display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? "Saving..." : "Save Intervention"}
                        </Button>
                    </div>
                </form>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </Container>
        </LocalizationProvider>
    );
}


interface InterventionGridProps {
    register: any;
    setValue: any;
    watch: any;
    errors?: any;
    disabled?: boolean;
    date: Date;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    overflowX: "auto",
    "& .vertical-header": {
        writingMode: "vertical-rl",
        transform: "rotate(180deg)",
        whiteSpace: "nowrap",
        padding: "0.5rem 0",
        minHeight: "120px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto",
        width: "100%",
    },
    "& .checkmark": {
        fontSize: "12px",
        color: theme.palette.text.primary,
    },
    "& td, & th": {
        border: `1px solid ${theme.palette.divider}`,
    },
    "& th": {
        backgroundColor: theme.palette.background.default,
    },
    "& .clinical-header": {
        backgroundColor: theme.palette.primary.light,
    },
    "& .psychological-header": {
        backgroundColor: theme.palette.success.light,
    },
    "& .social-header": {
        backgroundColor: theme.palette.warning.light,
    }
}));

export function MatrixFormView({ register, setValue, watch, errors, disabled = false, date }: InterventionGridProps) {
    return (
        <StyledPaper>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                    <tr>
                        <th colSpan={7}></th>
                        <th colSpan={11} className="clinical-header" style={{ padding: "8px", textAlign: "center", fontWeight: "bold" }}>
                            CLINICAL
                        </th>
                        <th colSpan={6} className="psychological-header" style={{ padding: "8px", textAlign: "center", fontWeight: "bold" }}>
                            PSYCHOLOGICAL
                        </th>
                        <th colSpan={13} className="social-header" style={{ padding: "8px", textAlign: "center", fontWeight: "bold" }}>
                            SOCIAL
                        </th>
                    </tr>
                    <tr>
                        {/* Header cells with vertical text */}
                        <th><div className="vertical-header">Date</div></th>
                        <th><div className="vertical-header">Time In</div></th>
                        <th><div className="vertical-header">Time Out</div></th>
                        <th><div className="vertical-header">Seen At</div></th>
                        <th><div className="vertical-header">Staff Initials</div></th>
                        <th><div className="vertical-header">Referred To</div></th>
                        <th><div className="vertical-header">Supervised</div></th>

                        {/* Clinical headers */}
                        <th className="clinical-header"><div className="vertical-header">1. ART Support</div></th>
                        <th className="clinical-header"><div className="vertical-header">2. General Health</div></th>
                        <th className="clinical-header"><div className="vertical-header">3. Medication</div></th>
                        <th className="clinical-header"><div className="vertical-header">4. Nutritional Support</div></th>
                        <th className="clinical-header"><div className="vertical-header">5. Pain Management</div></th>
                        <th className="clinical-header"><div className="vertical-header">6. Symptom Control</div></th>
                        <th className="clinical-header"><div className="vertical-header">7. HIV Infection</div></th>
                        <th className="clinical-header"><div className="vertical-header">8. TB Infection</div></th>
                        <th className="clinical-header"><div className="vertical-header">9. TB Screening</div></th>
                        <th className="clinical-header"><div className="vertical-header">10. TB Treatment</div></th>
                        <th className="clinical-header"><div className="vertical-header">11. VCT</div></th>

                        {/* Psychological headers */}
                        <th className="psychological-header"><div className="vertical-header">1. Adjustment</div></th>
                        <th className="psychological-header"><div className="vertical-header">2. Bereavement</div></th>
                        <th className="psychological-header"><div className="vertical-header">3. Counselling</div></th>
                        <th className="psychological-header"><div className="vertical-header">4. Memory Work</div></th>
                        <th className="psychological-header"><div className="vertical-header">5. Memory Boxes</div></th>
                        <th className="psychological-header"><div className="vertical-header">6. Resilience</div></th>

                        {/* Social headers */}
                        <th className="social-header"><div className="vertical-header">1. Access to Schooling</div></th>
                        <th className="social-header"><div className="vertical-header">2. Child Protection</div></th>
                        <th className="social-header"><div className="vertical-header">3. Economic Strengthening</div></th>
                        <th className="social-header"><div className="vertical-header">4. Education</div></th>
                        <th className="social-header"><div className="vertical-header">5. Financial Support</div></th>
                        <th className="social-header"><div className="vertical-header">6. Food Parcels</div></th>
                        <th className="social-header"><div className="vertical-header">7. Food Plates</div></th>
                        <th className="social-header"><div className="vertical-header">8. Grant Access</div></th>
                        <th className="social-header"><div className="vertical-header">9. HIV/AIDS Preventative</div></th>
                        <th className="social-header"><div className="vertical-header">10. Shelter</div></th>
                        <th className="social-header"><div className="vertical-header">11. Transport</div></th>
                        <th className="social-header"><div className="vertical-header">12. Vocational Training</div></th>
                        <th className="social-header"><div className="vertical-header">13. Spiritual</div></th>
                    </tr>
                </thead>
                <tbody>
                    {/* Sample data rows */}
                    <tr>
                        <td className="border border-gray-300 p-1 text-xs">09/11/2024</td>
                        <td className="border border-gray-300 p-1 text-xs">09:00</td>
                        <td className="border border-gray-300 p-1 text-xs">10:30</td>
                        <td className="border border-gray-300 p-1 text-xs">Ward A</td>
                        <td className="border border-gray-300 p-1 text-xs">JD</td>
                        <td className="border border-gray-300 p-1 text-xs">Oncology</td>
                        <td className="border border-gray-300 p-1 text-xs">DR.S</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-300 p-1 text-xs">08/11/2024</td>
                        <td className="border border-gray-300 p-1 text-xs">14:00</td>
                        <td className="border border-gray-300 p-1 text-xs">15:30</td>
                        <td className="border border-gray-300 p-1 text-xs">Ward B</td>
                        <td className="border border-gray-300 p-1 text-xs">MK</td>
                        <td className="border border-gray-300 p-1 text-xs">Pediatrics</td>
                        <td className="border border-gray-300 p-1 text-xs">DR.M</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-300 p-1 text-xs">07/11/2024</td>
                        <td className="border border-gray-300 p-1 text-xs">11:00</td>
                        <td className="border border-gray-300 p-1 text-xs">12:15</td>
                        <td className="border border-gray-300 p-1 text-xs">Clinic C</td>
                        <td className="border border-gray-300 p-1 text-xs">RS</td>
                        <td className="border border-gray-300 p-1 text-xs">Cardiology</td>
                        <td className="border border-gray-300 p-1 text-xs">DR.K</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                    </tr>
                    <tr>
                        <td className="border border-gray-300 p-1 text-xs">06/11/2024</td>
                        <td className="border border-gray-300 p-1 text-xs">10:00</td>
                        <td className="border border-gray-300 p-1 text-xs">11:45</td>
                        <td className="border border-gray-300 p-1 text-xs">Ward D</td>
                        <td className="border border-gray-300 p-1 text-xs">PT</td>
                        <td className="border border-gray-300 p-1 text-xs">Neurology</td>
                        <td className="border border-gray-300 p-1 text-xs">DR.L</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                        <td className="border border-gray-300 p-1 text-xs text-center"></td>
                        <td className="border border-gray-300 p-1 text-xs text-center">✓</td>
                    </tr>
                    {/* Input row */}
                    <tr>
                        <td style={{ padding: "8px" }}>
                            <DatePicker
                                value={dayjs(date)}
                                onChange={(newDate) => setValue("date", newDate?.toDate())}
                                disabled={disabled}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        fullWidth: true
                                    }
                                }}
                            />
                        </td>
                        <td style={{ padding: "8px" }}>
                            <TimePicker
                                value={dayjs(`2024-01-01T${watch("timeIn") || "00:00"}:00`)}
                                onChange={(newTime) => setValue("timeIn", newTime?.format("HH:mm") || "")}
                                disabled={disabled}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        fullWidth: true
                                    }
                                }}
                            />
                        </td>
                        <td style={{ padding: "8px" }}>
                            <TimePicker
                                value={watch("timeOut") ? dayjs(`2024-01-01T${watch("timeOut")}:00`) : null}
                                onChange={(newTime) => setValue("timeOut", newTime?.format("HH:mm") || "")}
                                disabled={disabled}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        fullWidth: true
                                    }
                                }}
                            />
                        </td>
                        <td style={{ padding: "8px" }}>
                            <TextField
                                {...register("seenAt")}
                                size="small"
                                disabled={disabled}
                                error={!!errors.seenAt}
                                fullWidth
                            />
                        </td>
                        <td style={{ padding: "8px" }}>
                            <TextField
                                {...register("staffInitials")}
                                size="small"
                                disabled={disabled}
                                error={!!errors.staffInitials}
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                fullWidth
                            />
                        </td>
                        <td style={{ padding: "8px" }}>
                            <TextField
                                {...register("referredTo")}
                                size="small"
                                disabled={disabled}
                                error={!!errors.referredTo}
                                fullWidth
                            />
                        </td>
                        <td style={{ padding: "8px" }}>
                            <TextField
                                {...register("supervised")}
                                size="small"
                                disabled={disabled}
                                error={!!errors.supervised}
                                fullWidth
                            />
                        </td>
                        {/* Clinical checkboxes */}
                        {[
                            "artSupport", "generalHealth", "medication", "nutritionalSupport",
                            "painManagement", "symptomControl", "hivInfection", "tbInfection",
                            "tbScreening", "tbTreatment", "vct"
                        ].map((field) => (
                            <td key={field} style={{ textAlign: "center" }}>
                                <Checkbox
                                    {...register(field)}
                                    disabled={disabled}
                                    size="small"
                                />
                            </td>
                        ))}
                        {/* Psychological checkboxes */}
                        {[
                            "adjustment", "bereavement", "counselling",
                            "memoryWork", "memoryBoxes", "resilience"
                        ].map((field) => (
                            <td key={field} style={{ textAlign: "center" }}>
                                <Checkbox
                                    {...register(field)}
                                    disabled={disabled}
                                    size="small"
                                />
                            </td>
                        ))}
                        {/* Social checkboxes */}
                        {[
                            "accessToSchooling", "childProtection", "economicStrengthening",
                            "education", "financialSupport", "foodParcels", "foodPlates",
                            "grantAccess", "hivAidsPreventative", "shelter", "transport",
                            "vocationalTraining", "spiritualInterventions"
                        ].map((field) => (
                            <td key={field} style={{ textAlign: "center" }}>
                                <Checkbox
                                    {...register(field)}
                                    disabled={disabled}
                                    size="small"
                                />
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </StyledPaper>
    );
}
