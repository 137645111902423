import { z } from "zod";

export const interventionFormSchema = z.object({
  date: z.date(),
  timeIn: z.string().regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/),
  timeOut: z.string().regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/),
  seenAt: z.string(),
  staffInitials: z.string().min(2).max(4),
  referredTo: z.string(),
  supervised: z.string(),
  
  // Clinical interventions
  artSupport: z.boolean().default(false),
  generalHealth: z.boolean().default(false),
  medication: z.boolean().default(false),
  nutritionalSupport: z.boolean().default(false),
  painManagement: z.boolean().default(false),
  symptomControl: z.boolean().default(false),
  hivInfection: z.boolean().default(false),
  tbInfection: z.boolean().default(false),
  tbScreening: z.boolean().default(false),
  tbTreatment: z.boolean().default(false),
  vct: z.boolean().default(false),

  // Psychological interventions
  adjustment: z.boolean().default(false),
  bereavement: z.boolean().default(false),
  counselling: z.boolean().default(false),
  memoryWork: z.boolean().default(false),
  memoryBoxes: z.boolean().default(false),
  resilience: z.boolean().default(false),

  // Social interventions
  accessToSchooling: z.boolean().default(false),
  childProtection: z.boolean().default(false),
  economicStrengthening: z.boolean().default(false),
  education: z.boolean().default(false),
  financialSupport: z.boolean().default(false),
  foodParcels: z.boolean().default(false),
  foodPlates: z.boolean().default(false),
  grantAccess: z.boolean().default(false),
  hivAidsPreventative: z.boolean().default(false),
  shelter: z.boolean().default(false),
  transport: z.boolean().default(false),
  vocationalTraining: z.boolean().default(false),
  spiritualInterventions: z.boolean().default(false),
});

export type InterventionFormData = z.infer<typeof interventionFormSchema>;
